class ShareDocumentService {
  constructor(connectedApis, onFilePicked) {
    this.connectedToGoogleDrive = false;
    this.authLoaded = false;
    this.connectedEmail = '';

    this.connectedApis = connectedApis;
    this.onFilePicked = onFilePicked;
    this.googleAuth = null;
    this.pickerApiLoaded = false;

    this.#insertGoogleApisScript();
    this.#setGoogleAuth();
  }

  loadPicker() {
    if (!this.connectedToGoogleDrive) { return; }

    if (this.pickerApiLoaded) {
      this.#createPicker();
    }
    else {
      gapi.load('picker', () => {
        this.pickerApiLoaded = true;
        this.#createPicker();
      });
    }
  }

  #setGoogleAuth() {
    this.googleAuth = this.connectedApis.find((a) => a.provider === 'google_oauth2');
    this.connectedToGoogleDrive = !!this.googleAuth;
    this.connectedEmail = this.googleAuth ? this.googleAuth.authEmail : '';
    this.authLoaded = true;
  }

  #createPicker() {
    const view = new google.picker.DocsView();
    view.setIncludeFolders(true);

    const picker = new google.picker.PickerBuilder();

    picker.enableFeature(google.picker.Feature.MULTISELECT_ENABLED);
    picker.enableFeature(google.picker.Feature.MINE_ONLY);
    picker.setOAuthToken(this.googleAuth.token);
    picker.addView(view);
    picker.setCallback(this.#pickerCallback.bind(this));

    picker.build().setVisible(true);
  }

  #pickerCallback(data) {
    if (data.action !== google.picker.Action.PICKED) { return; }

    data.docs.forEach((doc) => {
      this.onFilePicked(doc);
    });
  }

  #insertGoogleApisScript() {
    const src = 'https://apis.google.com/js/api.js';

    if (document.querySelector(`script[src="${src}"]`)) { return; }

    let googleApisScript = document.createElement('script');
    googleApisScript.setAttribute('src', src);
    document.head.appendChild(googleApisScript);
  }
}

export default ShareDocumentService;
