<template>
  <div class="shadowed-box">
    <div class="clearfix">
      <div :class="['share-button', { 'selected': usingGoogle }]" @click="changeShareMethod('Google')">
        <div class="bottom-5">
          <svg-icon name="google-drive" class="base-icon"></svg-icon>
        </div>

        <div class="tiny-header-text">
          Share Via Google Drive
        </div>
      </div>

      <div :class="['share-button', { 'selected': usingPriori }]" @click="changeShareMethod('Priori')">
        <div class="bottom-5">
          <svg-icon name="upload" class="base-icon"></svg-icon>
        </div>

        <div class="tiny-header-text">
          Upload From Device
        </div>
      </div>
    </div>

    <div v-show="usingGoogle">
      <loading-section name="documentSharing" :render-after-loading="true" class="padded">
        <google-sharer
          :is-lawyer="isLawyer"
          :project-id="projectId"
          :projects="projects"
          :connected-apis="connectedApis"
          :on-share-documents="shareDocuments"
          :on-disconnect-google="disconnectGoogle">
        </google-sharer>
      </loading-section>
    </div>

    <div v-show="usingPriori">
      <loading-section name="documentSharing" :render-after-loading="true" class="padded">
        <upload-sharer
          :is-lawyer="isLawyer"
          :projects="projects"
          :project-id="projectId"
          :on-share-documents="shareDocuments">
        </upload-sharer>
      </loading-section>
    </div>
  </div>
</template>

<script>
import GoogleSharer from 'vue-app/marketplace/shared/documents/google-sharer.vue';
import UploadSharer from 'vue-app/marketplace/shared/documents/upload-sharer.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import NotificationService from 'vue-app/shared/services/notification-service';
import Document from 'resources/document.js';
import Matter from 'resources/marketplace/matter.js';
import User from 'resources/user.js';

export default {
  name: 'DocumentSharingEditor',

  components: {
    GoogleSharer,
    UploadSharer,
    SvgIcon,
    LoadingSection
  },

  props: {
    isLawyer: {
      type: Boolean,
      required: true
    },

    projectId: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      shareMethod: 'Google',
      projects: [],
      connectedApis: {}
    };
  },

  computed: {
    usingGoogle() {
      return this.shareMethod === 'Google';
    },

    usingPriori() {
      return this.shareMethod === 'Priori';
    }
  },

  created() {
    LoadingService.loading('documentSharing');

    Promise.all([Matter.query({ open: true }), User.connectedApis()]).then(([projects, connectedApis]) => {
      this.projects      = projects;
      this.connectedApis = connectedApis.data;

      LoadingService.done('documentSharing');
    });
  },

  methods: {
    changeShareMethod(method) {
      this.shareMethod = method;
    },

    disconnectGoogle() {
      User.disconnectGoogle().then(response => {
        this.connectedApis = response.data;
      });
    },

    shareDocuments(documents, matterId, documentSource) {
      return Document.bulkCreate({ documents, matterId, documentSource })
        .then(() => {
          NotificationService.success('Successfully shared documents.', true);
          window.location.href = `/${this.isLawyer ? 'lawyer-app' : 'client-app'}/projects/${matterId}`;
        })
        .catch(() => {
          NotificationService.error('There were errors sharing the documents.', true);
          window.location.reload();
        });
    }
  }
};
</script>

<style scoped lang="scss">
  :deep(.padded .loading-modal) {
    padding: 10px 0 20px;
  }
</style>
