<template>
  <div class="normal-weight size-text-13px">
    <div class="box-content symmetrical">
      <div class="row" v-if="authLoaded && !connectedToGoogleDrive">
        <div class="col-sm-6 col-sm-offset-3 col-lg-4 col-lg-offset-4">
          <a href="/clients/auth/google_oauth2" class="nv-button-dark-green" target="_self">Connect to Google<span class="hidden-xs"> Drive</span></a>
        </div>
      </div>

      <div class="row top-20" v-if="authLoaded && !connectedToGoogleDrive">
        <div class="col-sm-10 col-sm-offset-1 size-text-11px">
          <div class="bottom-10">
            NOTE: connecting to Google Drive will provide Priori Legal with access to the following abilities:
          </div>

          <ul>
            <li>Upload, download, update, and delete files in your Google Drive</li>
            <li>Create, access, update, and delete native Google documents in your Google Drive</li>
            <li>Manage files and documents in your Google Drive (e.g., search, organize, and modify permissions and other metadata, such as title)</li>
          </ul>

          <div>
            We only use this access to provide you a view of documents you have available to share, and to provide you the ability to share those documents with other Priori users. You can see our privacy policy <a href="https://www.priorilegal.com/privacy-policy-dmca-copyright-policy" target="_blank">here</a>. Once connected, you can disconnect here or from your Google Drive account settings.
          </div>
        </div>
      </div>

      <div class="text-center" v-if="authLoaded && connectedToGoogleDrive">
        <div class="bottom-10">
          <svg-icon name="checkmark" class="base-icon green-text right-5"></svg-icon>
          Connected to Google Drive as <span class="semibold-weight">{{ connectedEmail }}</span>
        </div>

        <div class="size-text-11px">
          <a href="" class="gray-text" @click.prevent="showDisconnectConfirmationModal = true">Disconnect Google Drive</a>
        </div>
      </div>
    </div>

    <hr class="kenny-hr no-margin">

    <div class="box-content symmetrical" v-if="!selectedFiles.length">
      <div :class="['select-file-box', { 'disabled': !connectedToGoogleDrive }]" @click="loadPicker">
        <div class="bottom-15">
          <svg-icon name="google-drive" class="base-icon"></svg-icon>
        </div>

        <div class="semibold-weight">
          Select files to share via Google Drive
        </div>
      </div>
    </div>

    <div v-if="selectedFiles.length">
      <form name="form" class="a-form" novalidate>
        <div class="box-content symmetrical contrasting">
          <div class="left-30 right-30">
            <div class="row normal-weight dark-gray-text size-text-13px bottom-20 hidden-xs">
              <div class="col-md-5">
                File Name
              </div>

              <div class="col-md-3">
                Note
              </div>

              <div class="col-md-4">
                Permissions
              </div>
            </div>

            <div class="bottom-20">
              <hr class="kenny-hr no-margin">
            </div>

            <div v-for="file in selectedFiles">
              <div class="row">
                <div class="col-md-5 top-20 semibold-weight">
                  {{ file.name }}
                </div>

                <div class="col-md-3">
                  <input type="text" class="form-control" v-model="file.note" placeholder="Add a note here...">
                </div>

                <div class="col-md-4 dash-btn-container">
                  <div class="row tight-columns">
                    <div class="col-md-8 dash-btn-container">
                      <dropdown-select
                        id-label="language"
                        label-key="label"
                        value-key="value"
                        :initial-label="permissionLabelFor(file.permission)"
                        :options="permissionOptions"
                        @input="(permission) => setPermission(permission, file)">
                      </dropdown-select>
                    </div>

                    <div class="col-md-4 dash-btn-container">
                      <button type="button" class="nv-button-square-white a-button-size" @click="removeFile(file)"><svg-icon name="trash" class="base-icon"></svg-icon></button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="top-20 bottom-20">
                <hr class="kenny-hr no-margin">
              </div>
            </div>

            <div class="top-30">
              <dropdown-select
                id-label="language"
                label-key="label"
                value-key="value"
                :initial-label="projectLabel(selectedProject)"
                :placeholder="`Select ${ isLawyer ? 'Client' : 'Lawyer' }/Project`"
                :options="projectOptions"
                @input="(projectId) => selectProject(projectId)">
              </dropdown-select>
            </div>
          </div>
        </div>

        <hr class="kenny-hr no-margin">

        <div class="box-content">
          <div class="row top-10">
            <div class="col-sm-5 col-sm-offset-1 col-md-4 col-md-offset-2 bottom-10">
              <button type="button" class="nv-button-white smaller" @click="loadPicker" :disabled="sharing">Add Files</button>
            </div>

            <div class="col-sm-5 col-md-4 bottom-10">
              <button type="button" class="nv-button-purple-red smaller" @click="shareDocuments" :disabled="submitDisabled">Share Documents <span class="spinner left-5" v-show="sharing"></span></button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <request-confirmation
      message="Are you sure you want to disconnect from Google Drive? You will no longer be able to share documents."
      :show="showDisconnectConfirmationModal"
      @on-modal-yes="onDisconnectGoogle"
      @on-modal-no="() => showDisconnectConfirmationModal = false">
    </request-confirmation>
  </div>
</template>

<script>
import RequestConfirmation from 'vue-app/shared/components/request-confirmation.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';
import ShareDocumentService from 'vue-app/shared/services/share-document-service.js';
import  { find, some, orderBy } from 'lodash';

export default {
  name: 'GoogleSharer',

  components: {
    RequestConfirmation,
    SvgIcon,
    DropdownSelect
  },

  props: {
    isLawyer: {
      type: Boolean,
      required: true
    },

    projects: {
      type: Array,
      required: true
    },

    projectId: {
      type: String,
      required: false
    },

    connectedApis: {
      type: Array,
      required: true
    },

    onShareDocuments: {
      type: Function,
      required: true
    },

    onDisconnectGoogle: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      sharing: false,

      selectedProject: null,

      selectedFiles: [],

      permissionOptions: [
        {
          label: 'Read/Write',
          value: 'writer'
        },
        {
          label: 'Read Only',
          value: 'reader'
        }
      ],

      showDisconnectConfirmationModal: false
    };
  },

  computed: {
    projectOptions() {
      return this.orderedProjects.map((project) => {
        return {
          label: this.projectLabel(project),
          value: project.id
        };
      });
    },

    orderedProjects() {
      if (this.isLawyer) {
        return orderBy(this.projects, [this.companyOrClientName, 'nameForLawyersReference'], ['asc', 'asc']);
      }
      else {
        return orderBy(this.projects, [this.lowerCaseLawyerName, 'nameForClientsReference'], ['asc', 'asc']);
      }
    },

    submitDisabled() {
      return !this.selectedProject || !this.selectedFiles.length || this.sharing;
    },

    shareDocumentService() {
      return new ShareDocumentService(this.connectedApis, this.addFile);
    },

    connectedToGoogleDrive() {
      return this.shareDocumentService.connectedToGoogleDrive;
    },

    authLoaded() {
      return this.shareDocumentService.authLoaded;
    },

    connectedEmail() {
      return this.shareDocumentService.connectedEmail;
    }
  },

  created() {
    this.selectProject(this.projectId);
  },

  methods: {
    projectLabel(project) {
      if (!project) { return null; }

      if (this.isLawyer) {
        return `${project.client.businessName || project.client.fullName} (${project.nameForLawyersReference})`;
      }
      else {
        return `${project.lawyer.fullName} (${project.nameForClientsReference})`;
      }
    },

    setPermission(permission, file) {
      file.permission = permission;
    },

    permissionLabelFor(permission) {
      return find(this.permissionOptions, { value: permission }).label;
    },

    selectProject(projectId) {
      this.selectedProject = find(this.projects, { id: parseInt(projectId) });
    },

    shareDocuments() {
      if (!this.submitDisabled) {
        this.sharing = true;

        this.onShareDocuments(this.selectedFiles, this.selectedProject.id, 'Google')
          .finally(() => {
            this.sharing = false;
          });
      }
    },

    addFile(file) {
      if (!this.isSelected(file)) {
        file.permission = 'writer';
        this.selectedFiles.push(file);
      }
    },

    removeFile(file) {
      this.selectedFiles = this.selectedFiles.filter(item => item !== file);
    },

    isSelected(file) {
      return some(this.selectedFiles, (f) => (f.id === file.id));
    },

    companyOrClientName(matter) {
      return (matter.client.businessName || matter.client.fullName).toLowerCase();
    },

    lowerCaseLawyerName(matter) {
      return matter.lawyer.fullName.toLowerCase();
    },

    loadPicker() {
      this.shareDocumentService.loadPicker();
    }
  }
};
</script>
